<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import RichtextareaContent from 'components/richtextarea-content.vue'
import ProcessDefinitionListItem from 'list-items/process-definition-list-item.vue'
import TextControl from 'controls/text-control.vue'
import UsersControl from 'controls/users-control.vue'
import DataControl from 'controls/data-control'
import ProcessDefinition from 'mixins/models/process-definition'
import { updateValueForContentType } from 'helpers/content-types'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

export default {
  name: 'CreateWorkflowDialog',
  components: {
    RichtextareaContent,
    CustomDialog,
    ProcessDefinitionListItem,
    TextControl,
    UsersControl,
    DataControl
  },
  mixins: [ProcessDefinition],
  props: {
    definition: {
      type: [Object, String],
      default: undefined
    },
    predecessorWorkflowId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      dialogOpen: false,
      step: 1,
      queryText: null,
      queryItems: [],
      selectedDefinition: null,
      queryMaxResults: 10,
      definitionQueryRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (definitions) => {
            this.queryItems = definitions
          }
        }
      }),
      definitionShowRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (definition) => {
            this.selectedDefinition = definition
            this.gotoStep(2)
          }
        }
      }),
      newRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (workflow) => {
            this.workflow = workflow
          }
        }
      }),
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.dialogOpen = false
            this.$emit('success', data)
          }
        }
      }),
      workflow: null
    }
  },
  computed: {
    titleDialog () {
      if (this.step === undefined) return ''

      return this.selectedDefinition?.name || this.$t('workflow.createDialog.title')
    },
    cancelBtnDialogText () {
      if (this.step === undefined) return null
      if ((this.step === 1) || this.definition) return undefined

      return this.$t('general.buttons.back')
    },
    okBtnDialogText () {
      if ((this.step === undefined) || (this.step === 1)) return null
      if (this.isSingleTask) return this.$t('workflow.createDialog.buttons.ok.task')

      return this.$t('workflow.createDialog.buttons.ok.process')
    },
    errorMessage () {
      return this.definitionQueryRequestable.baseErrorMessage ||
          this.newRequestable.baseErrorMessage ||
          this.createRequestable.errorMessagesFullExceptValidationFor(['title', 'assignee'])
    },
    hintMessage () {
      if (this.queryItems.length < this.queryMaxResults) {
        return null
      } else {
        return 'Mehr Ergebnisse verfügbar. Bitte verfeinern Sie Ihre Suche.'
      }
    },
    isValid () {
      if (this.workflow) {
        return this.workflow.autoGenerateTitle || (this.workflow.title && this.workflow.title.trim().length > 0)
      }

      return false
    },
    workflowGroupIds () {
      return map(this.workflow.groups, 'id')
    },
    isSingleTask () {
      return this.selectedDefinition && this.processDefinitionIsSystemProcessDefinitionSingleTaskFor(this.selectedDefinition)
    },
    titleControlLabel () {
      return this.isSingleTask ? 'Titel der Aufgabe' : 'Titel des Prozesses'
    }
  },
  methods: {
    onDialogOpened () {
      this.step = undefined
      this.queryText = ''

      if (this.definition) {
        this.selectAndShowDefinitionDetails(this.definition)
      } else {
        this.gotoStep(1)
        this.query()
      }

      this.$emit('open')
    },
    onDialogClosed () {
      this.definitionQueryRequestable.cancelRequestable()
      this.newRequestable.cancelRequestable()
    },
    gotoStep (step) {
      if (this.step !== step) {
        this.step = step

        this.definitionQueryRequestable.resetRequestable()
        this.newRequestable.resetRequestable()
        this.createRequestable.resetRequestable()

        if (this.step === 1) {
          this.selectedDefinition = null
          this.workflow = null
        } else if (this.step === 2) {
          this.newRequestable.request({ method: Request.GET, url: this.$apiEndpoints.workflows.new() },
            { workflow_definition_id: this.selectedDefinition.id }, null, true)
        }
      }
    },
    selectAndShowDefinitionDetails (definition) {
      if (definition === 'system_process_definition_single_task') {
        this.definitionShowRequestable.request(
          { method: Request.GET, url: this.$apiEndpoints.workflowDefinitions.showSystemProcessDefinitionSingleTask() },
          null, null, true
        )
      } else {
        this.selectedDefinition = definition
        this.gotoStep(2)
      }
    },
    query () {
      this.queryItems = []
      this.definitionQueryRequestable.request(
        { method: Request.GET, url: this.$apiEndpoints.workflowDefinitions.list() },
        {
          except: ['single_task'],
          query: this.queryText,
          limit: this.queryMaxResults,
          filter_by_groups_only: true
        }, null, true)
    },
    queryTextChanged: debounce(function () {
      this.query()
    }, 500),
    createWorkflow () {
      this.createRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.workflows.create() },
        null,
        {
          workflow_definition_id: this.selectedDefinition.id,
          predecessor_workflow_id: this.predecessorWorkflowId,
          workflow: {
            ...(!this.workflow.autoGenerateTitle && { title: this.workflow.title }),
            assignee_id: this.workflow.assignee?.id
          },
          field_data: reduce(this.workflow.dataFields, function (result, field) {
            result[field.definition.id] = updateValueForContentType(field.definition.type, field.value)
            return result
          }, {})
        }
      )
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="titleDialog"
    fullheight
    :close-on-button-click="false"
    :cancel-btn-text="cancelBtnDialogText"
    :ok-btn-text="okBtnDialogText"
    :ok-btn-disabled="!isValid"
    :loading="createRequestable.requestableLoading"
    :error-message="errorMessage"
    content-class="create-workflow-dialog"
    @click-ok="createWorkflow"
    @click-cancel="(step === 1) || definition ? dialogOpen = false : gotoStep(step - 1)"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-container
      v-if="step === undefined"
      class="fill-height fluid"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-row>
    </v-container>
    <v-window
      v-else
      v-model="step"
      style="overflow: visible;"
    >
      <v-window-item :value="1">
        <div style="position: sticky; top: 0; z-index: 1; background-color: #fff;">
          <v-text-field
            v-model="queryText"
            :error-messages="definitionQueryRequestable.errorMessage"
            :hint="hintMessage"
            autofocus
            clearable
            class="pt-1"
            label="Suche"
            outlined
            persistent-hint
            @input="queryTextChanged"
          />
          <v-divider class="pb-2" />
        </div>
        <v-list class="py-0">
          <template v-if="!definitionQueryRequestable.requestableLoading">
            <template v-if="queryItems.length > 0">
              <v-subheader>Verfügbare Prozessvorlagen</v-subheader>
              <process-definition-list-item
                v-for="def in queryItems"
                :key="def.id"
                :value="def"
                @click="selectAndShowDefinitionDetails(def)"
              />
            </template>
            <v-list-item v-else>
              <v-list-item-content class="text-center">
                Keine Ergebnisse
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-else>
            <v-list-item-content class="text-center">
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-window-item>
      <v-window-item :value="2">
        <div v-if="selectedDefinition">
          <richtextarea-content :template="selectedDefinition.description" />
          <div class="mt-6">
            <template v-if="!newRequestable.requestableLoading && workflow">
              <text-control
                v-if="!workflow.autoGenerateTitle"
                v-model="workflow.title"
                :label="titleControlLabel"
                required
                :disabled="createRequestable.requestableLoading"
                :error-messages="createRequestable.validationErrorMessageFor('title')"
                class="mb-5"
              />
              <users-control
                v-model="workflow.assignee"
                :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list(), params: {group_ids: workflowGroupIds}}"
                label="Verantwortlich"
                single
                :disabled="createRequestable.requestableLoading"
                :error-messages="createRequestable.validationErrorMessageFor('assignee')"
                :to="() => undefined"
              />
              <data-control
                v-for="(dataField, index) in workflow.dataFields"
                :key="dataField.definition.id"
                v-model="dataField.value"
                :type="dataField.definition.type"
                :label="dataField.definition.name"
                :options="dataField.definition.options"
                :required="dataField.definition.required"
                :infobox="dataField.definition.infobox"
                :error-messages="createRequestable.validationErrorMessageFor(`field_${dataField.definition.id}`)"
                :class="{'mb-5': index < workflow.dataFields.length - 1}"
              />
            </template>
            <div
              v-else
              class="text-center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </custom-dialog>
</template>
