<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import InfiniteScrollList from './infinite-scroll-list.vue'
import debounce from 'lodash/debounce'
import some from 'lodash/some'
import findIndex from 'lodash/findIndex'

export default {
  name: 'SelectList',
  components: {
    InfiniteScrollList
  },
  mixins: [Requestable],
  model: {
    prop: 'selectedItems',
    event: 'change'
  },
  props: {
    ...requestablePropFactory().props,
    selectedItems: {
      type: Array,
      required: true
    },
    compareFunction: {
      type: Function,
      default: (a, b) => { return a.id === b.id }
    },
    filter: Boolean,
    excludedItemIds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      pageLoaded: 0,
      queryText: null,
      items: undefined
    }
  },
  methods: {
    load (doneCallback) {
      this.pageLoaded = this.pageLoaded + 1
      this.request(this.requestParameter, {
        page: this.pageLoaded, per_page: 10, query: this.queryText, except: this.excludedItemIds
      }, null, true, true, (data) => {
        if (this.items === undefined) this.items = []
        this.items.push(...data)

        if (data?.length === 10) {
          doneCallback('ok')
        } else {
          doneCallback('empty')
        }
      })
    },
    queryTextChanged: debounce(function () {
      this.pageLoaded = 0
      this.items = undefined
    }, 500),
    isSelected (item) {
      return some(this.selectedItems, value => { return this.compareFunction(value, item) })
    },
    toggleItem (item) {
      const index = findIndex(this.selectedItems, value => { return this.compareFunction(value, item) })
      if (index === -1) {
        this.$emit('change', this.selectedItems.concat([item]))
      } else {
        this.$emit('change', this.selectedItems.slice(0, index).concat(this.selectedItems.slice(index + 1)))
      }
    }
  }
}
</script>
<template>
  <div>
    <v-text-field
      v-if="filter"
      v-model="queryText"
      class="mt-4 flex-grow-0 hide-details"
      clearable
      label="Suche"
      outlined
      @input="queryTextChanged"
    />
    <infinite-scroll-list
      v-slot="{ item, index }"
      :items="items"
      :load-on-nth-last-item="1"
      @onLoad="load"
    >
      <slot
        :item="item"
        :index="index"
        :selected="isSelected(item)"
        :toggle="() => toggleItem(item)"
      />
    </infinite-scroll-list>
  </div>
</template>
<style scoped>
.v-text-field.hide-details :deep(.v-text-field__details) {
  display: none;
}
</style>
